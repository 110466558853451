.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  transition: 150ms ease-in-out;
  z-index: 0;
  position: relative;
  color: var(--white);
  margin: 0;
  width: 100%;
  height: auto;
}
.main__mobile {
  display: none;
}
.main.selected {
  position: relative;
  color: var(--white);
  margin: 0;
}

.main.selected:hover {
  cursor: default;
}
.main > div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.informations {
  width: 100%;
  height: 100%;
  gap: 0.7rem;
}
.container__informations {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  margin: 0.5rem 0;
}
.container__modalites {
  padding: 0.5rem 1rem;
  width: 350px;
}
.container__modalites > div {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  background-color: #f9f9f9;
  border-radius: 3px;
  height: 100%;
  color: var(--black);
  font-size: 0.8rem;
  opacity: 1;
  padding: 0.8rem;
}
.noContainer__modalites {
  display: none;
}
.noContainer__modalites div {
  display: none;
}
.top__container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: var(--green);
  font-size: 1rem;
  font-weight: bold;
  column-gap: 10px;
  row-gap: 5px;
}
.principe_actif {
  color: var(--white);
  font-weight: bold;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  width: 300px;
  height: 100%;
  background-color: var(--green);
  padding: 1rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.top__container span {
  position: relative;
  left: 5px;
  font-size: 0.8rem;
  font-weight: normal;
  text-decoration: underline;
  margin-right: 10px;
  cursor: pointer;
}

.container_variantes {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: auto;

  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  padding: 0.3rem 0.5rem;
  background-color: var(--white);
  border-radius: 5px;
  color: var(--dark-grey);
  font-size: 0.9rem;
  font-weight: normal;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.148);
  transition: 0.2s ease-in-out;
}
.top__container span:hover .container_variantes {
  opacity: 1;
  top: 150%;
}
.bottom__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: normal;
  gap: 5px;
}
.container_molecule {
  width: 98%;
  display: flex;
  flex-direction: column;
}
.container_molecule_name {
  height: auto;
  border-top: 1px solid var(--grey);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 7px 0;
}
.container_molecule_name:last-child {
  padding-bottom: 0px;
}
.molecule {
  width: 250px;
  color: var(--white);
  background-color: var(--green);
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 0.9rem;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.noIncomp {
  font-size: 0.8rem;
  color: var(--dark-grey);
  font-style: italic;
}
.container_condition {
  display: flex;
  gap: 5px;
}
.condition {
  display: flex;
  color: var(--white);
  background-color: var(--orange);
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 0.9rem;
  cursor: default;
  transition: 0.15s ease-in-out;
}
.noCondition {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.container_ecrasabilite {
  opacity: 1;
  transition: 0.15s ease-in-out;
  display: flex;
}
.noContainer_ecrasabilite {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.hidden {
  display: none;
}
.container__alternative {
  opacity: 1;
  transition: 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btns {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.btn_clear svg {
  display: block;
  fill: var(--white);
  width: 25px;
  height: 25px;
}

.printable_info,
.printable_alternatives_galeniques {
  display: none;
}

@media screen and (max-width: 1024px) {
  .top__container {
    width: 100%;
  }
  .principe_actif {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 846px) {
  .main {
    display: none;
  }
  .main__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    transition: 150ms ease-in-out;
    z-index: 0;
    position: relative;
    color: var(--white);
    margin: 0;
    width: 100%;
    height: auto;
    padding: 0.7rem;
  }
  .principe_actif {
    width: 90%;
    padding: 0;
  }
  .container__modalites {
    padding: 0.5rem 0rem;
    width: 100%;
  }
  .container__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .top__container span {
    display: none;
  }
  .top__left__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .btns {
    width: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 0rem;
  }
  .btn_add,
  .btn_clear {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_add svg {
    display: block;
    fill: var(--white);
    width: 30px;
    height: 30px;
  }

  .btn_clear svg {
    display: block;
    fill: var(--white);
    width: 30px;
    height: 30px;
  }
  .container_molecule_name {
    flex-wrap: wrap;
  }
  .molecule {
    width: 150px;
    font-size: 0.8rem;
  }
  .condition {
    font-size: 0.8rem;
  }
  .noIncomp {
    font-size: 0.7rem;
  }
}

@media print {
  .btns,
  .span_variantes {
    display: none !important;
  }

  .main,
  .main__mobile {
    padding: 1rem;
    page-break-inside: avoid;
  }

  .container_molecule_name {
    flex-wrap: wrap;
  }
  .molecule {
    width: 150px;
    font-size: 0.8rem;
  }
  .condition {
    font-size: 0.8rem;
  }
  .noIncomp {
    font-size: 0.7rem;
  }
}

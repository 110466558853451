:root {
  --color1: var(--green);
  --color2: var(--light-grey);
}
.content {
  box-sizing: border-box !important;
  padding-left: 20px;
  padding-right: 20px;
  width: 100% !important;
  height: 50vh !important;
  overflow-y: scroll !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
  border: 3px solid var(--green) !important;
  border-top: 0px solid !important;
  box-sizing: border-box !important;
}
.content::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: var(--color2); /* or add it to the track */
}
.content::-webkit-scrollbar-thumb {
  background: var(--color1);
}
.manipulation {
  display: block !important;
}
.manipulation .AmmAnnexeTitre2 {
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  text-align: left;
}
.AmmAnnexeTitre2 a {
  text-decoration: underline;
  color: #000000;
}
.manipulation_active {
  display: block !important;
}
.interaction {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 0 !important;
  border-bottom: none !important;
}

.selection_rcp {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 100%;
}

.btn_selection_rcp {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0 !important;
  border: 3px solid var(--green) !important;
  box-sizing: border-box !important;
  color: var(--green) !important;
  transition: 200ms ease-in-out !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  opacity: 1 !important;
}
.btn_selection_rcp.selected {
  background-color: var(--green) !important;
  color: white !important;
}
.btn_selection_rcp:hover {
  background-color: var(--green) !important;
  color: white !important;
}
.btn_selection_rcp:focus {
  background-color: var(--green) !important;
  color: white !important;
}
.btn_selection_rcp:nth-child(1) {
  border-right: 0 !important;
}
.btn_selection_rcp:nth-child(2) {
  border-right: 0 !important;
}

.btn_selection_rcp:nth-child(4) {
  border-right: 0 !important;
}

.rcp_name {
  width: 100vw !important;
}

/* Component containing both the selection field and the link */
.container_select {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--green) !important;
  border-left: 3px solid var(--green);
  border-right: 3px solid var(--green);
}

/* Button opening dropdown menu */
.btn_auto {
  box-sizing: border-box !important;
  width: 40% !important;
  height: auto !important;
  border: none !important;
  border-radius: 0 0 !important;

  color: var(--green) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  outline: none !important;
}

.btn_auto:hover {
  border: none !important;
  outline: none !important;
}

.btn_auto:nth-child(1) label {
  font-size: 20px !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
}
.btn_auto:nth-child(1):nth-child(1) fieldset {
  border: none !important;
  border-color: #00000000 !important;
}

/* Link button */
.link_rcp_site {
  margin-left: 10px !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: var(--color1) !important;
}
.link_rcp_site_logo {
  height: 40px !important;
  width: 40px !important;
}

.menuItem,
.label div:nth-child(1) {
  white-space: unset !important;
}

/* Label of the dropdown menu */
.label div:nth-child(1) {
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .btn_auto {
    width: 55% !important;
    padding: 1rem;
  }
  .btn_selection_rcp {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .btn_auto {
    width: 100% !important;
  }

  .container_select {
    border-left: 3px solid var(--green);
    border-right: 3px solid var(--green);
  }
  .selection_rcp {
    border-left: 3px solid var(--green);
    border-right: 3px solid var(--green);
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }

  .btn_selection_rcp {
    font-size: 14px !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-color: var(--light-grey) !important;
  }
  .btn_selection_rcp:nth-child(2) {
    border-top: 0 !important;
  }
  .btn_selection_rcp:nth-child(3) {
    border-top: 0 !important;
  }
}

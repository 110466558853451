.main {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  align-items: center;
}
.container_reference div:first-of-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.reference {
  transition: 300ms ease-in-out;
}
.container_reference span:first-of-type {
  display: none;
}
.reference:hover {
  /* background-color: var(--green); */
  color: var(--white);
  transition: 150ms ease-in-out;
}
.reference::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1000%;
  height: 0%;
  background-color: var(--green);
  z-index: -1;
  transition: 300ms ease-in-out;
}
.reference:hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--green);
  z-index: -1;
  transition: 150ms ease-in-out;
}
.title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.closeButton {
  align-self: flex-end;
}

.main table {
  vertical-align: top;
  padding: 5px;
}

.main table td {
  vertical-align: top;
  padding: 2px 5px;
}

.tabpanel {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .box {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .closeButton {
    align-self: center;
  }
}

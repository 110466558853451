.switch {
  justify-content: center;
  align-items: center;
  width: max-content;
}

.label {
  width: max-content;
}

@media screen and (max-width: 950px) {
  .toggles {
    height: 100%;
    width: 100%;
  }
  .label {
    width: 100%;
  }
}

.main {
  width: 424px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: fit-content;
}

.regleArea {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.selectContainer {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem;
  width: 65px;
  background-color: #fff;
  border-radius: 4px;
  border: none;
  padding: 0;
  box-sizing: border-box;
}
.customSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 8px;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: none;
  color: var(--dark-green);
  background: none;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.033);
}

.selectedOption {
  text-transform: uppercase;
}
.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1000;
  list-style: none;
  padding: 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.dropdownList .option {
  appearance: none;
  border: none;
  background-color: var(--green);
  color: var(--white);
  font-weight: bold;
  width: 100%;
  height: 35px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.dropdownList .option:hover {
  background-color: var(--white);
  color: var(--green);
}

.option__active {
  appearance: none;
  border: none;
  background-color: var(--white);
  color: var(--green);
  font-weight: bold;
  width: 100%;
  height: 35px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
@media screen and (max-width: 405px) {
  .main {
    padding: 0 !important;
  }
}

.main {
  position: relative;
  background-color: var(--light-red);
  display: flex;
  padding: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  transition: 0.2s ease-in-out;
  border: 1px solid var(--red);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.main:hover {
  background-color: var(--red);
  color: var(--white);
}
.contradictory {
  padding-right: 0.5rem;
}
.contradictory img {
  position: absolute;
  top: 5%;
  left: 99%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
}

.no_info {
  cursor: initial;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--light-grey);
  border: 1px solid var(--dark-grey);
}
.no_info:hover {
  background-color: var(--light-grey);
  color: var(--black);
}
.drugOptimal {
  color: var(--black);
  border-style: dashed;
  border-width: 2px;
}
.drugOptimal:hover {
  background-color: var(--red);
  color: var(--white);
}
.compatible {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--green);
  border: 1px solid var(--dark-green);
}
.compatible:hover {
  background-color: var(--dark-green);
  color: var(--white);
}

.logo__svg {
  width: 20px;
  height: 20px;
}

.material {
  background-color: var(--orange);
  padding: 2px 8px;
  border: 1px solid var(--dark-orange);
  border-radius: 0px;
}

.material:hover {
  background-color: var(--dark-orange);
  color: var(--white);
}

.main {
  display: grid;
  grid-template-rows: 40px auto 40px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 5px #0000000a;
  background-color: var(--light-grey);
  border-radius: 15px;
  margin: 5px;
}

.title {
  text-align: center;
  font-size: 20px;
  background-color: var(--green);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.drugs {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.selectSolvent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.selectSolvent > div {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--darker-green) !important;
  color: var(--white) !important;
}

.selectSolvent > div > div {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.selectSolvent > div > input {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.selectSolvent > div > svg {
  fill: var(--white) !important;
}
.selectSolvent *::before {
  display: none;
}

ul {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .main {
    grid-template-rows: 40px auto 40px;
    margin: 5px;
    border-radius: 10px;
  }

  .title {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    grid-template-rows: 50px auto 45px;
    height: auto;
  }

  .drugs {
    height: auto;
    overflow-y: visible;
  }
}

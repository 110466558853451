.toggles {
  width: 100%;
  height: fit-content;
  color: var(--black);
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
  gap: 20px;
}
hr {
  width: 100%;
  margin-top: 1rem;
  border: 0.5px solid var(--grey);
}
h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
  color: var(--dark-green);
}

.btns {
  width: 100%;
  margin-bottom: -0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch {
  width: 100%;
}

.label {
  width: max-content;
  gap: 1rem;
}

@media screen and (max-width: 950px) {
  .toggles {
    height: 100%;
    width: 100%;
  }
  .label {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .label > span:nth-child(2) {
    line-height: 1rem;
  }
}

@media screen and (max-width: 405px) {
  .label > span:nth-child(2) {
    font-size: 0.8rem;
    line-height: 0.6rem;
  }
}

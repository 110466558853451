.main {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 40vw;
  height: 80vh;
  border-radius: 10px;
}

.content {
  align-self: stretch;
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  border: none;
  padding: 2rem;
}

@media screen and (max-width: 1024px) {
  .main {
    width: 72vw;
  }
}

@media screen and (max-width: 768px) {
  .main {
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }

  .content {
    width: 100%;
    align-self: center;
    padding: 1rem;
  }

  .closeButton {
    width: 100%;
    justify-content: center;
  }
}

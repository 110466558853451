.import__photo {
  width: 330px;
  height: 80px;
  background-color: var(--green);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;
}
.interaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.imageContainer {
  margin-top: 10px;
  width: 100%;
  height: 250px;
  position: relative;
  border: 1px solid var(--green);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
}
.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}
.textfield__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.retardateur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffffac;
  font-size: 7rem;
}
.cameraContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.interaction__camera {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.container__loader {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.81);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.container__loader p {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green);
  margin-top: 1rem;
}
.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--green);
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--green) transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container__scan__text__pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

@media screen and (max-width: 1024px) {
  .container__scan__text__pc {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .interaction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

@-moz-document url-prefix() {
  .container__scan__text__pc {
    display: none;
  }
  .mozDiv {
    display: none;
  }
}

.main {
  position: relative;
  width: 100%;
  background-color: var(--green);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.title {
  color: var(--white);
  font-size: 2.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subtitle {
  color: var(--white);
  font-size: 1.2em;
  font-weight: 200;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 200px;
}

.logo > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 30px;
}

.button_list {
  display: flex;
  align-items: center;
}

.button_list:hover {
  border-radius: 0;
}

.btn {
  border-radius: 5px !important;
  border: 3px solid var(--white) !important;
  background-color: var(--green) !important;
  padding: 5px 15px !important;
  color: var(--white) !important;
  transition: 0.2s ease-in-out !important;
  font-size: 1rem !important;
  margin: 0 2px !important;
}
.btn:hover {
  background-color: var(--white) !important;
  color: var(--green) !important;
}
.btn.selected {
  background-color: var(--white) !important;
  color: var(--green) !important;
}

.btn.notSelected {
  border: 3px solid var(--white) !important;
  background-color: var(--green) !important;
  color: var(--white) !important;
}

/* Responsive */
.navbuttons {
  display: none;
}

@media screen and (max-width: 1080px) {
  .subtitle {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--green);
    padding: 0rem;
  }

  .btn {
    width: 33%;
    height: 100%;
    line-height: 0.9rem;
    font-weight: 600;
  }

  .button_list,
  .title {
    display: none;
  }
  .logo {
    position: relative;
    right: 0;
  }

  .navbuttons {
    display: flex;
    padding-top: 0px;
    padding-bottom: 5px;
    /* Align all butons in the view based on the margins: */
    width: 90%;
    height: 50%;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 330px) {
  .btn {
    font-size: 0.8rem !important;
  }
}

.dialogContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  width: 100%;
}

.errorMessage {
  color: var(--dark-red);
  font-weight: bold;
  font-size: 0.8em;
}

input {
  cursor: text;
}

:root {
  --light-green: #02dbca;
  --green: #00b0a2;
  --dark-green: #00887d;
  --darker-green: #006d64;
  --dark-red: red;
  --red: #ff8080;
  --orange: #ffb84d;
  --dark-orange: #ff9f46;
  --orange: #ffb84d;
  --dark-orange: #ff9f46;
  --light-red: rgb(244, 213, 213);
  --white: white;
  --black: black;
  --dark-grey: rgb(107, 106, 106);
  --mid-dark-grey: rgb(150, 150, 150);
  --grey: rgb(205, 205, 205);
  --light-grey: rgb(242, 239, 239);
  font-family: "Trebuchet MS", sans-serif;
}

.main {
  display: grid;
  grid-template-rows: 75px calc(100% - 105px) 30px;
  grid-template-columns: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .main {
    grid-template-rows: 90px calc(100% - 120px) 30px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.nombreMoyenLum {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.regleArea {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  width: 100% !important;
}

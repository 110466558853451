.selection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selection_regle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.selection_medicament {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  row-gap: 15px;
}
.selection_medicament_name {
  font-size: 1em;
  margin-top: 10px;
}

.selectDrugsArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
  padding: 10px 0;
}
.selectDrugsArea .drugContainer {
  background-color: var(--green);
  color: var(--white);
  font-size: 13px;
  height: 32px;
  width: 212px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
}
.drugName {
  font-weight: bold;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select :nth-child(1) {
  /* Overwrites default MUI props */
  white-space: unset !important;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dark-grey);
  border-radius: 5px;
  padding: 0px;
  transition: 150ms ease-in-out;
  z-index: 0;
  margin-right: 5px;
  width: 95%;
  height: 42px;
  overflow: hidden;
}

.main_active {
  opacity: 0;
}
.main.notSelected {
  background-color: var(--light-grey);
  margin: 5px;
}

.main.selected {
  position: relative;
  background-color: var(--green);
  color: var(--white);
}

.main.notSelected:hover {
  cursor: pointer;
  background-color: var(--green);
  color: var(--white);
}

.main.selected:hover {
  cursor: default;
  background-color: var(--green);
}

.btns {
  position: relative;
  height: 100%;
  width: 30%;
  display: flex;
  z-index: 1;
}
.main.notSelected .btns {
  display: none;
}
.name {
  width: 70%;
  height: 100%;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.nameSolvent {
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--dark-green);
}
.name p,
.nameSolvent p {
  color: var(--white);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.main.selected .btn_add:disabled {
  background-color: transparent;
  border-right: 0px solid var(--white);
}
.main.selected .btn_add:disabled svg {
  fill: transparent;
}
.main.selected .btn_add:hover {
  background-color: var(--white);
}
.main.selected .btn_add:hover svg {
  fill: var(--green);
}
.main.selected .btn_add {
  border-right: 2px solid var(--white);
  border-radius: 0;
  padding: 0;
  margin: 0;
  width: 50%;
  height: 100%;
  transition: 150ms ease-in-out;
  background-color: var(--dark-green);
}
.main.selected .btn_add svg {
  display: block;
  fill: var(--white);
  height: 100%;
}
.main.selected .btn_clear {
  border-radius: 0;
  padding: 0;
  margin: 0;
  width: 50%;
  height: 100%;
  background-color: transparent;
  transition: 150ms ease-in-out;
  background-color: var(--dark-green);
}
.main.selected .btn_clear:hover {
  background-color: var(--red);
}
.main.selected .btn_clear svg {
  display: block;
  fill: var(--white);
  height: 100%;
}
@media screen and (max-width: 1250px) {
  .btns {
    width: 35%;
  }
  .name {
    width: 65%;
  }
}
@media screen and (max-width: 1024px) {
  .main {
    margin-right: 0px;
    width: 100%;
    height: 35px;
  }
  .name {
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin-right: 0;
    width: 100%;
    border-width: 0;
    height: 42px;
  }

  .name {
    border-right: 2px solid var(--white);
    font-size: 14px;
  }
}

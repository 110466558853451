.main {
  padding: 0 1.5vw 0 1.5vw;
  grid-area: FilterAutocomplete;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey);
}

.btn_auto {
  box-sizing: border-box !important;
  width: 100% !important;
  height: 70% !important;
  border: none !important;
  border-radius: 0 !important;

  color: var(--green) !important;
  background-color: var(--white) !important;
  font-size: 1.2vw !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  outline: none !important;
}

.btn_auto:hover {
  border: none !important;
  outline: none !important;
}

.btn_auto:nth-child(1):nth-child(1) fieldset {
  border: none !important;
  border-color: var(--black) !important;
}
.btn_auto div:nth-child(1) {
  padding: 0 !important;
  height: 100% !important;
}

.btn_auto div:nth-child(1) div:nth-child(1) {
  padding: 0 !important;
  height: 1000% !important;
}

.btn_auto div:nth-child(1) div:nth-child(1) div:nth-child(1) {
  padding: 0 !important;
  height: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  color: var(--dark-grey) !important;
  font-style: italic;
  border-bottom: 2px solid var(--dark-grey);
  border-radius: 0 !important;
}

@media screen and (max-width: 1024px) {
  .main {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .main {
    background-color: var(--white);
    padding: 0;
    width: 100%;
    height: fit-content;
  }

  /* Dealing with the border of the searchBar */
  .searchBar :nth-child(2) :nth-child(3) {
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--dark-grey);
    border-radius: 0;
  }

  .searchBar :nth-child(2) {
    padding-top: 0;
  }
}

.main {
  cursor: initial;
  width: 20px;
  height: 20px;
  background-color: var(--red);
  border: 1px solid var(--dark-red);
  cursor: pointer;
  margin: 0 3px;
  transition: 0.2s ease-in-out;
}
.main:hover {
  background-color: var(--dark-red);
}

.main.drug {
  border-radius: 50%;
}
.main.solvent {
  border-radius: 0%;
}
.true__solvent {
  display: none;
}
.noInfo {
  cursor: initial;
  background-color: var(--light-grey);
  border: 1px solid var(--dark-grey);
}
.noInfo:hover {
  background-color: var(--dark-grey);
  color: var(--black);
}
.compatible {
  cursor: initial;
  background-color: var(--green);
  border: 1px solid var(--dark-green);
}
.compatible:hover {
  background-color: var(--dark-green);
  color: var(--white);
}
@media screen and (max-width: 1024px) {
  .main {
    width: 15px;
    height: 15px;
  }
}

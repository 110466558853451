.main {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 30% 70%;
  grid-template-areas: "DrugsIncompatibilitiesView BlocksView";
}
@media screen and (max-width: 1024px) {
  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .main {
    display: flex;
    overflow-y: scroll;
    height: 100%;
  }
}

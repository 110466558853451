.main,
.hidden {
  grid-area: BlocksView;
  display: grid;
  grid-template-rows: 50px calc(100% - 50px);
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .main {
    height: 55%;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--black);
  }
}

@media screen and (max-width: 768px) {
  .main {
    grid-area: BlocksView;
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    grid-template-rows: auto auto;
    height: 100%;
    overflow-y: scroll;
  }
  .hidden {
    display: none;
  }
}

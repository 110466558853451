.container__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.container__name label {
  font-size: 1rem;
  color: var(--dark-grey);
  font-weight: bold;
}

.main {
  padding: 0 1.5vw;
  grid-area: FilterAutocomplete;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--light-grey);
}

@media screen and (max-width: 1024px) {
  .main {
    padding-top: 0;
    padding-right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: transparent;
  }
}

/* Not yet on the design */
@media screen and (max-width: 768px) {
  .main {
    display: flex;
    padding: 0.5rem;
  }
}

.main div:nth-child(3) div:nth-child(1) {
  max-width: 1000px !important;
}

.main div:nth-child(3) div:nth-child(1) > div:first-of-type {
  overflow-y: unset !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 25px !important;
  text-align: center;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: auto;
}

@media screen and (max-width: 1024px) {
  .main div:nth-child(3) div:nth-child(1) {
    height: 80%;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .buttonContainer {
    /* Overwrites MUI props: */
    justify-content: center !important;
    align-items: center !important;
  }
  .content {
    display: flex;
    align-self: center;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    border-radius: 0;
    border: 3px solid var(--green);
    box-sizing: border-box;
    /* Overwrites MUI props: */
    padding: 0 0 !important;
  }
  .content :nth-child(1) {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main div:nth-child(3) div:nth-child(1) {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .buttonContainer {
    /* Overwrites MUI props: */
    justify-content: center !important;
    align-items: center !important;
  }
  .content {
    display: flex;
    align-self: center;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    border-radius: 0;
    border: 3px solid var(--green);
    box-sizing: border-box;
    /* Overwrites MUI props: */
    padding: 0 0 !important;
  }
  .content :nth-child(1) {
    width: 100%;
  }
}

.main {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  grid-area: DrugsListPanel;
}

.menu__desktop {
  display: flex;
  align-items: center;
  max-height: 100%;
  width: 90%;
  justify-content: space-between;
  gap: 10px;
}
.container_medicament,
.container_ordonnances {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}
.toggles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.interdiction {
  display: flex;
}
.condition {
  display: flex;
}
.label > span:nth-child(2) {
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.legende {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.legende > div {
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.legendeInterdiction {
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
}
.legendeCondition {
  background-color: var(--orange);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
}
.menu__tablet {
  display: none;
}
.menu__mobile {
  display: none;
}
.container__autocomplete__ordonnance {
  display: none;
  z-index: 10000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.226);
}

@media screen and (max-width: 1024px) {
  .menu__desktop {
    display: flex;
  }
  .menu__tablet {
    display: none;
    align-items: center;
    max-height: 100%;
    width: 90%;
    justify-content: space-between;
  }
  .interdiction,
  .condition {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .legendeInterdiction {
    border-radius: 5px;
    height: 30px;
    font-size: 0.9rem;
    width: 90px;
  }
  .legendeCondition {
    border-radius: 5px;
    height: 30px;
    font-size: 0.9rem;
    width: 152px;
  }
  .menu__mobile {
    display: none;
  }
  .container__autocomplete__ordonnance {
    display: none;
    z-index: 10000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.226);
  }
}

@media screen and (max-width: 846px) {
  .menu__desktop {
    display: none;
  }
  .menu__tablet {
    display: none;
  }
  .menu__mobile {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
  .container__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .container__autocomplete__ordonnance {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .container__autocomplete__ordonnance {
    display: flex;
    position: fixed;
    bottom: 78px;
  }
}

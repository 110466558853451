@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,500;1,600&display=swap");

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Trebuchet MS";
  font-style: normal;
  font-size: 0.95rem;
}

* {
  box-sizing: border-box;
}

.main {
  grid-area: IncompatibilitiesRampes;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-grey);
}

.title {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background-color: var(--white);
  box-shadow: 0px 10px 13px 5px rgb(0 0 0 / 5%);
}
.incompatibilities {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

@media screen and (max-width: 1024px) {
  .main {
    height: calc(100% - 60px);
    overflow-y: auto;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .incompatibilities {
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: initial;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    background-color: var(--white);
    height: 100%;
    overflow-y: scroll;
    justify-content: space-between;
    flex-direction: column;
  }

  .incompatibilities {
    display: block;
    overflow-y: auto;
    height: fit-content;
  }
}

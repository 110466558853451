.main {
  position: relative;
  display: grid;
  grid-template-columns: auto 50px;
  grid-template-rows: 100%;
}

.screen {
  z-index: 1;
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  z-index: 999;
}

@media screen and (max-width: 1024px) {
  .main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 95% 5%;
    grid-template-columns: 100%;
    z-index: 999;
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: calc(100% - 50px) 50px;
    grid-template-columns: 100%;
    z-index: 999;
  }

  .panel {
    flex-direction: row;
  }
}

.main {
  display: flex;
  margin: 0px;
  margin-left: 0px;
  background-color: rgb(226 226 226);
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.main:nth-child(odd) {
  background-color: rgb(241, 241, 241);
}

.incompatibilities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.noIncompMessage {
  margin-left: 10px;
  font-style: italic;
  color: var(--dark-grey);
  font-size: 0.8em;
}

@media screen and (max-width: 1024px) {
  .main {
    width: 100%;
    margin: 0px;
  }

  .incompatibilities {
    width: 50%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0px;
  }
  .drug {
    width: 95%;
  }
  .incompatibilities {
    width: auto;
  }
}

.import__photo {
  width: 330px;
  height: 80px;
  background-color: var(--green);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;
}
.interaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin: 1rem 3rem;
}

@media screen and (max-width: 768px) {
  .interaction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

/* Desktop CSS */
.content {
  width: 100% !important;
  height: 100% !important;
  overflow: scroll !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
  border: 3px solid var(--green) !important;
  box-sizing: border-box !important;
}
.tableCell {
  text-align: center !important;
}
.tableCell:nth-child(1) {
  width: 15%;
}
.tableCell:nth-child(2) {
  width: 25%;
}
.tableCell:nth-child(3) {
  width: 15%;
}
.tableCell:nth-child(4) {
  width: 20%;
}
.tableCell:nth-child(5) {
  width: 25%;
}
.tableCell_row {
  text-align: justify !important;
}

.interaction {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

/* Hiding or showing data depending on screen size */
.smallView,
.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  /* Hide unwanted laptop view */
  .largeView {
    display: none;
  }

  /* Base definition for the look on a small screen */
  .smallView {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* CSS to keep the same look as RCP Ramps */
  .displayedInfo {
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    border-radius: 0;
    border: 0;
  }
  .displayedInfo::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: var(--color2);
  }
  .displayedInfo::-webkit-scrollbar-thumb {
    background: var(--color1);
  }

  /* CSS to deal with the look of the displayed text */
  .shown {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  .shown .title:nth-child(1) {
    border-top: 0;
  }

  .title {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: black;
    padding: 0;
    margin-top: 10px;
    padding: 5px;
    border-top: 1px solid var(--dark-grey);
  }
  .text {
    width: 100%;
    font-size: 13px;
    color: black;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Buttons styles (fits with the RCP buttons styles) */
  .button {
    width: 100%;
    height: auto;
    text-transform: uppercase;
    /* Modifications du style de base MUI*/
    font-size: 15px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 0 !important;
    border-bottom: 3px solid var(--green) !important;
    box-sizing: border-box !important;
    transition: 200ms ease-in-out !important;
    font-size: 13px !important;
    opacity: 1 !important;
  }
  .selected {
    background-color: var(--green) !important;
    color: var(--white) !important;
  }
  .unselected {
    background-color: var(--light-grey) !important;
    color: var(--green) !important;
  }
  .button:hover {
    background-color: var(--green) !important;
    color: var(--white) !important;
  }
  .button:focus {
    background-color: var(--green) !important;
    color: var(--white) !important;
  }
}

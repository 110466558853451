.main div:nth-child(3) {
  width: 100% !important;
}
.main div:nth-child(3) div:nth-child(1) {
  max-width: 100% !important;
}
.main div:nth-child(3) div:nth-child(1) > div:first-of-type {
  overflow-y: unset !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_table {
  overflow-y: auto;
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .main div:nth-child(3) {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
  }
  .main div:nth-child(3) div:nth-child(1) {
    height: auto;
    width: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .content_table {
    width: 100%;
    align-self: flex-start;
  }
  .content {
    height: 100%;
  }
}

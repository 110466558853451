p {
  color: #000000;
}
a {
  color: #000000;
  text-decoration: underline;
}
a img {
  display: none;
}
.AmmAnnexeTitre1 {
  font-size: 13px;
  line-height: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  text-align: left;
}
.AmmAnnexeTitre2 {
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  text-align: left;
}
.AmmAnnexeTitre3 {
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  color: #000000;
  text-decoration: underline;
}
.AmmAnnexeTitre4 {
  font-style: italic;
  font-weight: normal;
  font-variant: normal;
  color: #000000;
  text-decoration: underline;
}
.AmmAnnexeTitre1 a,
.AmmAnnexeTitre2 a,
.AmmAnnexeTitre3 a,
.AmmAnnexeTitre4 a {
  text-decoration: underline;
}
.AmmCorpsTexteGras {
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 0.25em;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 1em;
  color: #000000;
}
.AmmCorpsTexte {
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin-top: 0px;
  padding-top: 0.25em;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 1em;
  color: #000000;
}
.AmmListePuces1,
.AmmListePuces2 {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1cm;
}
.AmmListePuces1 span,
.AmmListePuces2 span {
  font-family: Symbol;
}
.gras {
  font-weight: bold;
}

.main {
  position: relative;
  background-color: var(--green);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  font-size: 0.8em;
}

@media screen and (max-width: 1024px) {
  .legalInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .versionLittle {
    display: flex;
  }

  .versionBig {
    display: none;
  }

  .contact {
    align-items: center;
  }

  .legalInfo {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
@media screen and (max-width: 405px) {
  .main {
    font-size: 0.6rem;
  }
}

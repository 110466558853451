.main {
  width: 100%;
  height: 100%;
  grid-area: IncompatibilitiesView;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.incompatibilities {
  position: relative;
  width: 90%;
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

@media screen and (max-width: 1024px) {
  .title {
    display: none;
  }

  .main {
    background-color: var(--white);
    border: 0px;
    height: 95%;
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .title {
    display: none;
  }

  .main {
    background-color: var(--white);
    border: 0px;
    height: fit-content;
  }
}

@media print {
  @page {
    margin-top: 3cm;
    margin-bottom: 3cm;
  }

  body::before {
    content: url("../../../images/logo_drugoptimal_vert.png");
    position: fixed;
    top: 1.5rem;
    left: 1rem;
    height: 2cm;
  }

  body::after {
    content: "Copyright © 2023 | DrugOptimal | Ceci n'est pas une ordonnance";
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--green);
    color: var(--white);
    text-align: center;
    padding: 2mm;
  }

  .main {
    position: absolute;
    top: 1.7cm;
    left: 0;
    right: 0;
    overflow: visible;
    page-break-after: always;
    page-break-before: always;
    height: fit-content;
    margin-bottom: 2cm;
  }

  .incompatibilities {
    width: 100%;
    height: auto;
    overflow: visible;
    page-break-inside: avoid;
    page-break-after: auto;
  }
}

.main div:nth-child(3) {
  width: 100% !important;
}
.main div:nth-child(3) div:nth-child(1) {
  max-width: 100% !important;
}
.main div:nth-child(3) div:nth-child(1) > div:first-of-type {
  overflow-y: unset !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content_table {
  overflow-y: auto;
  overflow-x: auto;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-top: 20px;
}
.horizontal {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-top: 20px;
}
.orga_box {
  border: 2px solid red;
  padding: 15px;
  width: 90%;
  max-width: 90%;
  text-align: left;
  margin: 0 0 0 0;
}
.orga_arrow {
  font-size: 60px;
  font-weight: bold;
}
.organigram {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}
.orga_sep {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -17px;
  margin-bottom: -9px;
}
.note {
  margin-top: 10px;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .main div:nth-child(3) {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
  }
  .main div:nth-child(3) div:nth-child(1) {
    height: auto;
    width: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .content_table {
    width: 100%;
    align-self: flex-start;
  }
  .content {
    height: 100%;
  }
}

:root {
  --color1: var(--green);
  --color2: var(--light-grey);
}
.main {
  width: 100%;
}
.header_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.onglet {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%; */
}
.btn_rcp,
.btn_hug {
  height: 30px !important;
  padding: 20px 70px !important;
  border-radius: 7px 7px 0 0 !important;
  background-color: var(--color2) !important;
  color: var(--green) !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 200ms ease-in-out !important;
}
.btn_rcp:hover,
.btn_hug:hover {
  background-color: var(--green) !important;
  color: var(--color2) !important;
}
.btn_rcp.selected {
  background-color: var(--green) !important;
  color: white !important;
}
.btn_hug.selected {
  background-color: var(--green) !important;
  color: white !important;
}

.hug_text {
  padding: 5px 20px;
  size: 0.8em;
  font-style: italic;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .onglet {
    height: 100%;
    width: 100%;
  }
  .btn_rcp,
  .btn_hug {
    width: 50% !important;
    height: 100% !important;
    font-size: 15px !important;
    padding: 10px 10px !important;
  }
  .hug_text {
    display: none;
  }
}

hr {
  width: 60%;
  margin: 30px 0;
}
.title {
  margin: 0;
  margin-bottom: 20px;
}
.clear {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container__listes {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.scroll__section {
  height: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.liste__prescriptions,
.liste__oral_prescriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.liste__all__prescriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.liste__prescriptions > div,
.liste__all__prescriptions > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

input {
  cursor: pointer;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid var(--green);
  transition: 0.2s ease-out;
}
input:checked {
  background-color: var(--green);
}
label {
  font-size: 1rem;
  text-align: left;
  width: 300px;
}

@media (max-width: 768px) {
  /* The Mui Dialog container */
  .main div:nth-child(3) div:nth-child(1) {
    width: 100%;
    margin: 0;
  }
  .container__listes {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  label {
    width: 250px;
  }
}

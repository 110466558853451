:root {
  --color1: var(--green);
  --color2: var(--light-grey);
}
.main {
  width: 100%;
}

.header_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.onglet {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}
.btn_rcp,
.btn_hug {
  height: 30px !important;
  padding: 20px 70px !important;
  border-radius: 7px 7px 0 0 !important;
  background-color: var(--color2) !important;
  color: var(--green) !important;
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 200ms ease-in-out !important;
}
.btn_rcp:hover,
.btn_hug:hover {
  background-color: var(--green) !important;
  color: var(--color2) !important;
}
.btn_rcp.selected {
  background-color: var(--green) !important;
  color: var(--white) !important;
}
.btn_hug.selected {
  background-color: var(--green) !important;
  color: var(--white) !important;
}
.tableCell,
.tableCell_row {
  border-right: 2px solid var(--color2) !important;
}
.tableCell:last-child,
.tableCell_row:last-child {
  border-right: 0px solid var(--color2) !important;
}

/* Responsive View Stylesheet */
.smallScreen {
  display: none;
  flex-direction: column;
  width: 100%;
}

.smallScreen .title:nth-child(1) {
  border-top: 0;
}

.shown {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: black;
  padding: 0;
  border-top: 1px solid grey;
  padding-bottom: 5px;
}
.text {
  width: 100%;
  font-size: 15px;
  color: black;
  text-align: center;
  padding: 10px;
  padding-top: 0;
}

@media screen and (max-width: 1024px) {
  .bigScreen,
  .hidden {
    display: none;
    padding: 0;
  }

  .smallScreen {
    display: flex;
  }
}

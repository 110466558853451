.main,
.hidden {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.container__filter {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .main,
  .hidden {
    height: 45%;
    overflow: visible;
  }
  .container__filter {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: row;
    background-color: var(--darker-green);
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 100%;
  }
  .hidden {
    display: none;
  }
  .container__filter {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: var(--darker-green);
  }
}

.main {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 85%;
  grid-template-areas: "DrugsListPanel" "IncompatibilitiesView";
  /* display: flex;
  flex-direction: column; */
}
@media screen and (max-width: 1024px) {
  .main {
    grid-template-columns: 100%;
    grid-template-rows: 15% auto;
    grid-template-areas: "DrugsListPanel" "IncompatibilitiesView";
  }
}

@media screen and (max-width: 768px) {
  .main {
    grid-template-columns: 100%;
    grid-template-rows: 15% auto;
    grid-template-areas: "DrugsListPanel" "IncompatibilitiesView";
    overflow: scroll;
  }
}

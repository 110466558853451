.main {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .main {
    width: 100%;
  }
}

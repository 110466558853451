.main {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  background-color: var(--light-grey);
  align-items: center;
  border-bottom: 2px solid var(--white);
  transition: 0.2s ease-in-out;
}
.main:hover {
  background-color: var(--white);
}

.leftArrow,
.rightArrow,
.upArrow,
.downArrow {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--green);
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.leftArrow:hover,
.rightArrow:hover,
.upArrow:hover,
.downArrow:hover {
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}
.container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.drugName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  font-size: 15px;
  color: var(--green);
  font-weight: bold;
}
.drugName p {
  color: var(--green);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.incompatibilities {
  width: 195px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.incompatibilities > div {
  margin: 5px 3px;
}

.upArrow,
.downArrow {
  display: none;
}
@media screen and (max-width: 1454px) {
  .container {
    flex-direction: column;
  }
  .drugName {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .incompatibilities {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .upArrow,
  .downArrow {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .drugName {
    font-size: 13px;
  }
  .drugName p {
    color: var(--green);
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 8px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .drugName {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .incompatibilities {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .upArrow,
  .downArrow {
    display: flex;
  }
  .leftArrow,
  .rightArrow {
    display: none;
  }
  .arrow {
    font-size: medium;
  }
}

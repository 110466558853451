.main {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .main {
    height: calc(100% - 60px);
    padding: 0.5rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 100%;
    flex-direction: column;
    justify-self: center;
    row-gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    overflow-y: visible;
    /* Align block width with the width of the butons */
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.main {
  color: var(--white);
  background-color: var(--green);
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 0.9rem;
  cursor: default;
  font-weight: normal;
}

@media screen and (max-width: 1024px) {
  .main {
    padding: 5px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    width: auto;
    margin: 0;
    font-size: 0.8rem;
    border-radius: 3px;
  }
}

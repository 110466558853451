.main {
  position: relative;
  background-color: var(--darker-green);
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.interaction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1vw;
  width: 100%;
  padding: 0 1rem;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1vw;
  width: auto;
  height: 100%;
}

.menu_rampes {
  width: 350px;
  height: 41px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
}
h2 {
  font-size: 15px;
  color: #006d64;
}

.select > div {
  background-color: transparent !important;
}

.indicateur_act,
.indicateur_inact {
  cursor: initial;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0 3px;
  transition: 0.2s ease-in-out;
}

.indicateur_act {
  background-color: var(--green);
  border: 1px solid var(--dark-green);
}

.indicateur_inact {
  background-color: var(--mid-dark-grey);
  border: 1px solid var(--dark-grey);
}

@media screen and (max-width: 1024px) {
  .main {
    height: 60px;
  }
  .interaction {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 100%;
    background-color: var(--light-grey);
    border-bottom-color: var(--darkgrey);
    border-width: 1px;
    border-bottom-style: solid;
  }

  .interaction {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 10px;
    margin-top: 10px;
  }

  .menu_rampes,
  .buttons {
    width: 90%;
    margin-left: 0;
  }

  .buttons {
    justify-content: space-between;
  }

  .btn_algo,
  .buttons > span {
    width: 100% !important;
  }
}

.main {
  display: flex;
  width: 100vw;
  justify-content: center;
}
.main :nth-child(1) {
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .main :nth-child(1) {
    margin: 0 !important;
    max-width: none !important;
  }
}

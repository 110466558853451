.containerRegle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid var(--green);
  border-radius: 10px;
  overflow: hidden;
}

.containerRegle .infoRegle {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

.infoRegle h1 {
  font-size: 13px;
  margin: 0;
  color: var(--green);
  text-transform: uppercase;
}

.infoRegle h2 {
  font-size: 12px;
  margin: 0;
  color: var(--black);
  font-weight: bold;
}

.infoRegle p {
  font-size: 12px;
  margin: 0;
  color: var(--black);
  font-weight: 300;
}

.noDrugs {
  color: var(--red);
  font-weight: bold;
  font-size: 18px;
}

.main {
  display: flex;
  padding: 10px 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);
}
.drug {
  width: 40%;
  height: 40px;
}
.incompatibilities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 58%;
  gap: 3px;
}
.compatibilities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 58%;
}
.noIncompMessage {
  margin-left: 10px;
  font-style: italic;
  color: var(--dark-grey);
  font-size: 0.8em;
}

@media screen and (max-width: 1024px) {
  .main {
    width: 100%;
    display: flex;
    padding: 7px 50px;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid var(--grey);
    flex-direction: column;
    gap: 5px;
  }
  .main:nth-child(1n) {
    border-right: 1px solid var(--grey);
  }
  .drug {
    width: 100%;
    height: auto;
  }
  .incompatibilities {
    width: 100%;
  }
  .compatibilities {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 3%;
    border-bottom: 0;
  }

  .drug,
  .incompatibilities {
    width: 100%;
    height: fit-content;
  }
}

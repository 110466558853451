.main {
  padding: 20px 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 500px;
  z-index: 1000000;
}

@media screen and (max-width: 1024px) {
  .main {
    height: 40px;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: var(--white);
    padding: 0;
    gap: 0;
  }
}

@media screen and (max-width: 330px) {
  .button,
  .logout {
    padding: 5px !important;
  }
}
